import React from "react";
import "./notFound.css";

/* Country Not Found component */

const NotFound = () => {
  return (
    <div className="notfound">
      <p>Oops, Country not found!</p>
    </div>
  );
};

export default NotFound;
